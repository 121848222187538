import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"
import Chart from "chart.js/dist/chart.js"
import ClipboardJS from 'clipboard'

document.addEventListener('DOMContentLoaded', () => {
  const clipboard = new ClipboardJS('.is-clipboard', {
    text: function(trigger) {
      return trigger.getAttribute('data-clipboard-text');
    }
  });
});

Rails.start()
Turbolinks.start()

import "controllers"
